import React from "react";
import "./Testimonial.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import {useRef} from "react";

// import { Pagination } from "swiper";
import "swiper/css/pagination";
import profilePic1 from "../../img/profile1.jpg";
import profilePic2 from "../../img/profile2.jpg";
import profilePic3 from "../../img/profile3.jpg";
import profilePic4 from "../../img/profile4.jpg";
import { Autoplay, Pagination, Navigation } from 'swiper';

const Testimonial = () => {
  const clients = [
    {
      img: profilePic1,
      review:
        ` I'm so glad I chose your company for my project. Your team did an amazing job and the end result was even better than I expected. Highly recommend!

        Your team delivered an outstanding job. I'm really impressed with the quality of work and the attention to detail. Highly recommend!
        
        The quality of your work was exceptional.`,
    },
    {
      img: profilePic2,
      review:
        ` My project was completed on time and above expectations.

        The quality of your work was exceptional. I couldn't have asked for a better experience.
        I was overwhelmed with the level of professionalism from your team. The finished product was even better than I had imagined.`, 
    },
    {
      img: profilePic3,
      review:
        `I just wanted to take a moment to thank you for the incredible job you did on my project. The end result was better than I could have ever imagined and I'm so grateful for your hard work and dedication. Your attention to detail and professionalism really made this project a success and I look forward to working with you again soon...`,
    },
    {
      img: profilePic4,
      review:
        `I'm so pleased with the results of the project! Your team did a fantastic job, and I appreciate all the hard work that went into it. Great communication and attention to detail throughout the process.`,
    },
  ];
  const progressCircle = useRef(null);
  const progressContent = useRef(null);
  const onAutoplayTimeLeft = (s, time, progress) => {
    progressCircle.current.style.setProperty('--progress', 1 - progress);
    progressContent.current.textContent = `${Math.ceil(time / 1000)}s`;
  };

  return (
    <div className="t-wrapper" id="testimonial">
      <div className="t-heading">
        <span>Delivering </span>
        <span>Exceptional Work </span>
        <span>to Clients</span>
      <div className="blur t-blur1" style={{ background: "var(--purple)" }}></div>
      <div className="blur t-blur2" style={{ background: "skyblue" }}></div>

      </div>
      <Swiper
        // install Swiper modules
        // modules={[Pagination]}
        // slidesPerView={1}
        // pagination={{ clickable: true }}
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        onAutoplayTimeLeft={onAutoplayTimeLeft}
        className="mySwiper"
      >
        {clients.map((client, index) => {
          return (
            <SwiperSlide key={index}>
              <div className="testimonial">
                <img src={client.img} alt="" />
                <span className="review">{client.review}</span>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

export default Testimonial;
