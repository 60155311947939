import React from "react";
import { Link } from "react-scroll";
import "./Footer.css";
// import Wave from "../../img/wave.png";
// import Insta from "@iconscout/react-unicons/icons/uil-instagram";
// import Facebook from "@iconscout/react-unicons/icons/uil-facebook";
// import Gitub from "@iconscout/react-unicons/icons/uil-github";

const Footer = () => {
  return (
 
    <footer id="footer-wrapper" className="container-fluid">
    <div className="footer-area container">
         <div className="footer-ribbon"><span>Contact</span>
             
         </div>
         <div className="footer-top-area row">
             <div className="footer-column-first ">
                 <ul className="address">
                     <li>
                         <p><i className="fa fa-phone">&zwnj;</i> <strong>Phone:</strong>&nbsp;+91 9471453748</p>
                         <p></p>
                     </li>
                     
                     <li>
                         <p><i className="fa fa-envelope">&zwnj;</i> <strong>Email:</strong>&nbsp;scs.gamer39@gmail.com</p>
                     </li>
                 </ul>

           
             </div>
             {/* <div className="footer-column-second"> </div>
             <div className="footer-column-third"></div>
             <div className="footer-column-fourth"></div> */}
         </div>

    </div>
     <div className="container" >
  {/* <div className="footer_area_cp row">
                 <div className="footer-bottom-copyright"> 
                      <div className="region region-footer-bottom-copyright">    
                     </div>
                 </div> 
     </div> */}
     </div> 
     <div className="container">
     <div className="row">
     <div className="footer-bottom-copyright copyright">
                     <p>© Copyright. All Rights Reserved.</p>
                 </div>
                 
                 <div className="footer-bottom-copyright col-12 col-md-6">
                 <ul className="nab navbar-nav">
                     <li className="nav-item lststyl">
                         <Link to="/" className="nav-link" >Home</Link>
                     </li>
                     <li className="lststyl linpipe">|</li>
                     <li className="nav-item lststyl ">
                         <Link to="/About" className="nav-link " >About Us</Link>
                     </li>
                     <li className="nav-item lststyl linpipe">|</li>
                     <li className="nav-item lststyl">
                         <Link to="/contact" className="nav-link" >Contact</Link>
                      </li>
                 </ul>
 
                 </div>

                 <div className="social_area">
                 <li className="social-icons-facebook blokwise"><a href="https://www.facebook.com/" target="_blank" title="Facebook"><i className="fa fa-facebook">&zwnj;</i></a></li>
                 <li className="social-icons-twitter blokwise"><a href="https://twitter.com/" target="_blank"><i className="fa fa-twitter">&zwnj;</i></a></li>
                 <li className="social-icons-linkedin blokwise"><a href="	https://www.linkedin.com/" target="_blank" title="Linkedin"><i className="fa fa-linkedin">&zwnj;</i></a></li>

                 </div>
     </div>
     </div>

 </footer>
  );
};

export default Footer;
