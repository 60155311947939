import React, { useContext, useRef, useState } from "react";
import "./Contact.css";
import emailjs from "@emailjs/browser";
import { themeContext } from "../../Context";
const Contact = () => {
  
  const theme = useContext(themeContext);
  const darkMode = theme.state.darkMode;
  const form = useRef();
  const [done, setDone] = useState(false)
  const sendEmail = (e) => {
    e.preventDefault();
              

    emailjs
      .sendForm(
        "service_6z9c0ll",
        "template_2lndveb",
        form.current,
        "UaD1RmDKsxhGUAkS_"
      )
      .then(
        (result) => {
          console.log(result.text);
          setDone(true);
         form.reset();

        },
        (error) => {
          console.log(error.text);
        }
      );
      //  👇️ clear all input values in the form
       e.target.reset();
       
  };

  return (
    <div className="contact-form" id="contact">
      {/* left side copy and paste from work section */}
      <div className="w-left">
        <div className="awesome">
          {/* darkMode */}
          <span style={{color: darkMode?'white': ''}}>Get in Touch</span>
          <span>Contact me</span>
          <div
            className="blur s-blur1"
            style={{ background: "#ABF1FF94" }}
          ></div>
        </div>
      </div>
      {/* right side form */}
      <div className="c-right">
        <form ref={form} onSubmit={sendEmail}>
          <input type="text" name="user_name" className="user"  placeholder="Name" required pattern="^[A-Za-z\s]{1,}[\.]{0,1}[A-Za-z\s]{0,}$" title="Please write name in alphabets only. No numbers"/>
          <input type="email" name="user_email" className="user" placeholder="Email" required/>
          <textarea name="message" className="user" placeholder="Message" required/>
          {/* <input type="submit" value="Send" className="button"/> */}
          <button className="main" type="submit" value="Send">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            Send
        </button>
          <span>{done && "I received your mail ! Thanks for Contacting me !!"}</span> 
          
          <div
            className="blur c-blur1"
            style={{ background: "var(--purple)" }}
          ></div>
        </form>
      </div>
    </div>
  );
};

export default Contact;
